import axios from "axios";

const LogoutToken = () =>{

    const buttonClicked = async () => {
        const config = {
            headers: { 
                "Authorization": `Token ${localStorage.getItem('token')}`
            },
            
        };
        try {
            const results = await axios.post('/api/v1/rest-auth/logout/', {}, config);
            console.log(results);
            localStorage.removeItem('token');
        }catch (err) {
            console.log(err);
        }
        
    };

    return (
        <>
        <button onClick={buttonClicked}>Logout</button>
        </>
    );
}

export default LogoutToken;