import { useCallback} from "react";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import { Form, Field } from "react-final-form";

const useStyles = makeStyles({
  });

const Registration = ()=>{
    const formData = {};
    const classes = useStyles();

    const navigate = useNavigate();

    const styles ={}
    const setLocalStorage = useCallback(
        async (statusCode, token) => {
            if(statusCode === 201 && token){
                localStorage.setItem('token', token);
                navigate('/localnews');
            }
        }, [navigate]);
    
    const onSubmit = async (values) => { 
        const results = await axios.post(
            "/api/v1/rest-auth/registration/",
            values);
        console.log("results", results);
        console.log("results?.data", results?.data);
        await setLocalStorage(results?.status, results?.data?.key);
    };
  
    return (
      <div className={styles.wrapper}>
        <div className={classes.title}>React Final Form - Registration</div>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...formData,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
                errors.email = "Required";
              }
            if (!values.password1) {
              errors.password1 = "Required";
            }
            if (!values.password2) {
              errors.password2 = "Required";
            } else if (values.password1 !== values.password2) {
              errors.password2 = "Must match";
            }
            return errors;
          }}
          render={({ handleSubmit, 
            form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field name="email">
                {({ input, meta }) => (
                <div>
                  <label>Email</label>
                  <input {...input} type="email" placeholder="Please input email" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>

                <Field name="password1">
                {({ input, meta }) => (
                <div>
                  <label>Password</label>
                  <input {...input} type="password" placeholder="Password" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>

                <Field name="password2">
                {({ input, meta }) => (
                <div>
                  <label>Confirm Password</label>
                  <input {...input} type="password" placeholder="confirm Password" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>
              
              <div className={styles.buttons}>
                <button type="submit" disabled={submitting || pristine}>
                  Submit
                </button>
                <button
                  type="button"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  Reset
                </button>
              </div>
              <pre>{JSON.stringify(values, 0, 2)}</pre>
              <pre>{JSON.stringify(formData, 0, 2)}</pre>
            </form>
          )}
        />
      </div>
    );

    return (
        <h2>api/v1/rest-auth/registration/</h2>
    )
};

export default Registration;