import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import useAxiosPrivate from './hooks/useAxiosPrivate';


const LocalNews = () =>{

    const [localNewsList, setLocalNewsList] = useState([]);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        
        (async ()=> {
            const request = {
                method: 'get',
                url: '/api/v1/localnews/'
            };
            const results = await axiosPrivate(request);
            console.log('results in localnews', results);
            setLocalNewsList(results.data);
        })();
        return () => {
            setLocalNewsList([]);
        }
    }, []);

    const refreshThisPage = async ()=> {
        const request = {
            method: 'get',
            url: '/api/v1/localnews/'
        };
        const results = await axiosPrivate(request);
        console.log('results in localnews', results);
        setLocalNewsList(results.data);
    };

    return (
        <>
        <h1>{localNewsList[Math.floor(Math.random()*localNewsList.length)]?.title}</h1>
        <Link to="/changepassword" title="Change Password">Change Password</Link><br />
        <br />
        <button onClick={refreshThisPage}>refresh this page</button>
        </>
    )
}

export default LocalNews;