import axios from "axios";

const LogoutJWT = () => {
    const buttonClicked = async () => {
        try {
            const results = await axios.post(
                "/api/v1/rest-auth/api-jwt-logout/",
                {refresh: localStorage.getItem("token")},
            );
            console.log(results);
            localStorage.removeItem("token");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <button onClick={buttonClicked}>Logout</button>
        </>
    );
};

export default LogoutJWT;
