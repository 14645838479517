import { useState, useEffect } from "react";
// import axios from "axios";
import {axiosInstance } from '../api/axios';
import printJS from 'print-js';

const PrintPDF = () => {
    const [isButtonDisabled, setButtonDisabled]= useState(false);
    const [printData, setPrintData] = useState(null);

    useEffect(() =>{
        (async () => {
            setButtonDisabled(true);
            const response = await axiosInstance.get(
                "https://linglingli.ca/download_pdf_by_id/3", // https://linglingli.ca/download_pdf_by_id/1
                { responseType: "blob", withCredentials: true, credentials:'include' }
            );
            console.log(response);
            console.log(
                "response.headers['content-disposition']",
                response.headers["content-disposition"]
            );
            console.log(
                "file_name",
                response.headers["content-disposition"]
                    .split("filename=")[1]
                    .split(";")[0]
            );
            const fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0]
                .replace(/"|'/g, "");
    
            try{
                    setPrintData(response.data)
            }catch (error) {
                console.log("error in catch block", error);
            }finally{
                console.log('This is in finally block');
                setButtonDisabled(false);
            }
            
        })();

    }, []);
    //https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
    const printButtonClicked = () => {
        var url = window.URL.createObjectURL(new Blob([printData], {type: 'application/pdf'})); //make sure this is Blob type
        setTimeout(()=>URL.revokeObjectURL(url), 1000);

        return new Promise((resolve, reject) => {
            const array =[1,2];
            printJS({
                printable: url,
                type:'pdf',
                showModal: true,
                // onPrintDialogClose: () => { console.log("close"); setButtonDisabled(false); resolve(array)}, //this does not work on Firefox
                onError: (err) => {console.log("error"); reject(err)},
                onLoadingStart: ()=> { console.log("loading..."); resolve(array)},
            });
        });

    };


    return (
        <>
            <button onClick={printButtonClicked} disabled={isButtonDisabled}>{isButtonDisabled? 'Wait...' :'Click to print'}</button>
        </>
    );
};

export default PrintPDF;
