import { useCallback} from "react";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import { Form, Field } from "react-final-form";

const useStyles = makeStyles({
  });

const ResetPasswordRequest = (props) => {
    const formData = {};
    const classes = useStyles();

    const navigate = useNavigate();

    const styles ={}


    const requestConfirmed = useCallback(
        () => {
            navigate('/resetpasswordrequestconfirmed');
        }, [navigate]);
    
    const onSubmit = async (values) => { 
        const results = await axios.post(
            "/api/password_reset/",
            {
                ...values,
                'reset_new_password_path': '/resetnewpassword', // custom reset_new_password_path in back end
            },
        );
        console.log("results", results);
        console.log("results?.data?.code", results?.data?.code);
        requestConfirmed();
    };
  
    return (
      <div className={styles.wrapper}>
        <div className={classes.title}>React Final Form - Simple Example</div>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...formData,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            }
            return errors;
          }}
          render={({ handleSubmit, 
            form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field name="email">
                {({ input, meta }) => (
                <div>
                  <label>Email</label>
                  <input {...input} type="email" placeholder="Email address" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>

              <div className={styles.buttons}>
                <button type="submit" disabled={submitting || pristine}>
                  Submit
                </button>
                <button
                  type="button"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  Reset
                </button>
              </div>
              <pre>{JSON.stringify(values, 0, 2)}</pre>
              <pre>{JSON.stringify(formData, 0, 2)}</pre>
            </form>
          )}
        />
      </div>
    );
};

export default ResetPasswordRequest;