import axios from "axios";

const headerConfig = (access) => ({
    headers: { 
        "Authorization": `Bearer ${access}`
    },
    withCredentials: true
});

const getAccessToken = async () => {
    try {
        return await axios.post("/api/v1/token/refresh/",
            { refresh: localStorage.getItem('token')} // Note: this is refresh token
        );
    }catch(err) {
        console.log(err);
        console.log(err.message);
    }
}

export const authenticateRequest = async (method) =>{
    const results = await getAccessToken();
    console.log('authenticateRequest, results', results);
    const access = results?.data?.access;

    if(method ==='get'){
        return axios.get("/api/v1/localnews/", headerConfig(access))
    }
}

export const authGetRequest = async (path) =>{
    const results = await getAccessToken();
    console.log('results in authGetRequest:', results);
    const access = results?.data?.access;

    return axios.get(path, headerConfig(access))
};

export const authPutRequest = async (path, data) =>{
    const results = await getAccessToken();
    console.log('results in authGetRequest:', results);
    const access = results?.data?.access;

    return axios.put(path, data, headerConfig(access))
};

export const authPostRequest = async (path, data) =>{
    const results = await getAccessToken();
    console.log('results in authGetRequest:', results);
    const access = results?.data?.access;

    return axios.post(path, data, headerConfig(access))
};