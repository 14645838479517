import { useState, useEffect } from 'react';
import axios from 'axios';

const DownloadPDF = () =>{

    //https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
    const saveData = (blob, fileName)=> {
        console.log(blob);
        /*
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
        */
  
        //history.push("/login"); //works here
        var url = window.URL.createObjectURL(new Blob([blob])); //make sure this is Blob type
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName; //or, a.setAttribute('download', 'file.pdf');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
  
    };

    const downloadFromAPI = async () =>{
        const response = await axios.get('https://linglingli.ca/download_pdf_by_id/1', {responseType: 'blob'});
        console.log(response);
        console.log("response.headers['content-disposition']", response.headers['content-disposition']);
        console.log("file_name", response.headers['content-disposition'].split('filename=')[1].split(';')[0]);
        const fileName = (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/"|'/g, '');
        saveData(response.data, fileName);
    }

    return(
        <>
        <button onClick={downloadFromAPI}>Click to Download</button>
        </>
    )
}

export default DownloadPDF;