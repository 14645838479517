import { useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Form, Field } from "react-final-form";
import useAuth from "./hooks/useAuth";
import styles from './Login.module.css';

const Login = () => {
    const [tokenPairs, setTokenPairs] = useState(null);
    const formData = {};

    const navigate = useNavigate();

    const { setAuth } = useAuth();

    const setLocalStorage = useCallback(
        async (statusCode, jwt_token) => {
            if(statusCode === 200 && jwt_token){
                localStorage.setItem('token', jwt_token);
    
                navigate('/localnews');
            }
        }, [navigate]);
    
    const onSubmit = async (values) => { 
        const results = await axios.post("/api/v1/token/", values);
        console.log("results", results);
        console.log("results?.status", results?.status);
        setAuth({user: values.email, access: results?.data.access});
        await setLocalStorage(results?.status, results?.data?.refresh);
    };

    return (
      <div className={styles.wrapper}>
          <div className={styles.title}>
            <span className={styles.label}>React Final Form - Simple Example </span>
          </div>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...formData,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            }
            if (!values.password) {
              errors.password = "Required";
            }
            return errors;
          }}
          render={({ handleSubmit, 
            form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field name="email">
                {({ input, meta }) => (
                <div>
                  <label>Email</label>
                  <input {...input} type="email" placeholder="Email Address" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>

                <Field name="password">
                {({ input, meta }) => (
                <div>
                  <label>Password</label>
                  <input {...input} type="password" placeholder="Password" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>
              
              <div className={styles.buttons}>
                <button type="submit" disabled={submitting || pristine}>
                  Submit
                </button>
                <button
                  type="button"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  Reset
                </button>
              </div>
              <pre>{JSON.stringify(values, 0, 2)}</pre>
              <pre>{JSON.stringify(formData, 0, 2)}</pre>
            </form>
          )}
        />
      </div>
    );
};

export default Login;
