import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { makeStyles } from '@mui/styles';
import { authGetRequest, authPostRequest } from "./utils/axiosRequest";
import { Form, Field } from "react-final-form";

const useStyles = makeStyles({
    form: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
  });

const UserProfile = () =>{
    const classes = useStyles();
    const styles ={};
    const attachmentsRef = useRef(null);
    const [userProfile, setUserProfile] = useState({});

    useEffect(() => {
        
        (async ()=> {
            const results = await authGetRequest("/api/v1/user/profile/");
            console.log('results in userProfile', results);
            setUserProfile(results.data);
        })();
        return () => {
            setUserProfile({});
        }
    }, []);
    
    

    const buttonClicked = async () => {
        const config = {
            headers: { 
                "Authorization": `Token ${localStorage.getItem('token')}`
            },
            
        };
        // try {
        //     const results = await axios.post('/api/v1/rest-auth/logout/', {}, config);
        //     console.log(results);
        //     localStorage.removeItem('token');
        // }catch (err) {
        //     console.log(err);
        // }
        console.log(config);
        
    };

    const onSubmit = async (values) => { 
         const form_data = new FormData();
         form_data.set("first_name", values.first_name);
         form_data.set("last_name", values.last_name);
         form_data.set("user_profile.telephone", values.user_profile.telephone);
         form_data.set("user_profile.mobile", values.user_profile.mobile);
         form_data.set("user_profile.website", values.user_profile.website);
         
         console.log("values.user_profile.telephone", values.user_profile.telephone);
         
         // for(let index=0; index<attachmentsRef.current.files.length; index++){
        //     let f= attachmentsRef.current.files[index];
        //     console.log(f)
        //     //form_data.append('file', f);
        //     form_data.append('files', f);
        // }

        const results = await authPostRequest(
            "/api/v1/user/profile/",
            form_data);

        console.log("results", results);
        console.log("results?.data", results?.data);
        setUserProfile(results?.data?.user_info);
        // await setLocalStorage(results?.data?.code, values);
        console.log('token', results?.data?.refresh);

        localStorage.setItem('token', results.data.refresh);
    };

    return (
        <div className={styles.wrapper}>
          <div className={classes.title}>React Final Form - Add new Local News</div>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...userProfile,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.first_name) {
                errors.first_name = "Required";
              }
              if (!values.last_name) {
                errors.last_name = "Required";
              }
              return errors;
            }}
            render={({ handleSubmit, 
              form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                  <Field name="first_name">
                  {({ input, meta }) => (
                  <div>
                    <label>First Name</label>
                    <input {...input} type="text" placeholder="First Name" data-testid="first_name"/>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
                  </Field>
  
                  <Field name="last_name">
                  {({ input, meta }) => (
                  <div>
                    <label>Last Name</label>
                    <input {...input} type="text" placeholder="Last Name" data-testid="last_name"/>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
                  </Field>

                  <Field name="user_profile.telephone">
                  {({ input, meta }) => (
                  <div>
                    <label>Telephone</label>
                    <input {...input} type="text" placeholder="Telephone" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
                  </Field>

                  <Field name="user_profile.mobile">
                  {({ input, meta }) => (
                  <div>
                    <label>Mobile</label>
                    <input {...input} type="text" placeholder="Mobile" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
                  </Field>
  
                
                <div className={styles.buttons}>
                  <button type="submit" disabled={submitting || pristine} data-testid="submit_button">
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                  >
                    Reset
                  </button>
                </div>
                <pre>{JSON.stringify(values, 0, 2)}</pre>
                <pre>{JSON.stringify(userProfile, 0, 2)}</pre>
              </form>
            )}
          />
        </div>
      );
}

export default UserProfile;