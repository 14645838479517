import { useState, useEffect } from 'react';
//https://stackoverflow.com/questions/64269821/use-mozilla-pdf-js-in-create-react-app-with-typescript
// import * as pdfjsLib from "pdfjs-dist"; //this is my old version
import {version, GlobalWorkerOptions, getDocument } from "pdfjs-dist";

console.log("pdfjsLib version", version); //current: 2.13.216, so I create a folder, v2.13.216.

//copied from pdfjs-dist/build
// pdfjsLib.GlobalWorkerOptions.workerSrc = `${window.location.origin}/assets/js/v${pdfjsLib.version}/pdf.worker.min.js`; //works! - Hua
// GlobalWorkerOptions.workerSrc = `${window.location.origin}${process.env.PUBLIC_URL}/assets/js/v${version}/pdf.worker.min.js`; //works too! - Hua
GlobalWorkerOptions.workerSrc = `/assets/js/v${version}/pdf.worker.min.js`; // works too - Hua
// pdfjsLib.GlobalWorkerOptions.workerSrc ="pdfjs-dist/build/pdf.worker.js"


const PdfViewer = () => {
  const [pdf, setPDF] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      setLoading(true);
    let url = 'https://linglingli.ca/download_pdf_by_id/3';
    (async()=>{
        try {
            const loadingTask = getDocument(url);
            const pdfData = await loadingTask.promise;

            setPDF(pdfData);
            setLoading(false);
            console.log("Page rendered!");
        } catch (error) {
            console.log(error);
        }
    })();
  }, []);

  useEffect(() => {
      if(pdf){
        setNumPages(pdf.numPages);
        console.log(pdf);
      }
    
  }, [pdf]);

  useEffect(() => {
      if(!pdf){
          return;
      }
      
    (async (page_number) => {
        setLoading(true);
        let canvas_old = document.getElementById("pdf") ;
        if (canvas_old) {
            let context = canvas_old.getContext("2d");
            context.clearRect(0, 0, canvas_old.width, canvas_old.height);
            canvas_old.remove();
        }

        try {
            // Load information from the first page.
            const page = await pdf.getPage(page_number);

            const viewport = page.getViewport({ scale: 1 });

            // Apply page dimensions to the <canvas> element.
            var canv = document.createElement("canvas");
            canv.id = "pdf";
   
            canv.style.setProperty("width", "99%", "important");

            var div_pdf = document.getElementById("pdf-container");
            if (div_pdf) {
                let pdf_bottom_control_container_ele = document.getElementById(
                    "pdf-bottom-control-container"
                );
                div_pdf.insertBefore(
                    canv,
                    pdf_bottom_control_container_ele
                );
            }
            const canvas = document.getElementById("pdf");
            if (canvas) {
                const context = canvas.getContext("2d");
                context.clearRect(0, 0, canvas.width, canvas.height);
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render the page into the <canvas> element.
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                await page.render(renderContext);
                console.log("Page rendered after page number changed!");
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    })(pageNumber);
}, [pageNumber, pdf]);

  /*
  useEffect(() => {
    //https://pspdfkit.com/blog/2018/render-pdfs-in-the-browser-with-pdf-js/
    let url = 'https://linglingli.ca/download_pdf_by_id/3';
    (async (page_number) => {
        let canvas_old = document.getElementById("pdf") ;
        if (canvas_old) {
            let context = canvas_old.getContext("2d");
            context.clearRect(0, 0, canvas_old.width, canvas_old.height);
            canvas_old.remove();
        }

        try {
            const loadingTask = pdfjsLib.getDocument(url);
            const pdf = await loadingTask.promise;

            setNumPages(pdf.numPages);

            // Load information from the first page.
            const page = await pdf.getPage(page_number);

            const viewport = page.getViewport({ scale: 1 });

            // Apply page dimensions to the <canvas> element.
            var canv = document.createElement("canvas");
            canv.id = "pdf";
   
            canv.style.setProperty("width", "99%", "important");
            canv.style.setProperty("height", "99%", "important");

            var div_pdf = document.getElementById("pdf-container");
            if (div_pdf) {
                let pdf_bottom_control_container_ele = document.getElementById(
                    "pdf-bottom-control-container"
                );
                div_pdf.insertBefore(
                    canv,
                    pdf_bottom_control_container_ele
                );
            }
            const canvas = document.getElementById("pdf");
            if (canvas) {
                const context = canvas.getContext("2d");
                context.clearRect(0, 0, canvas.width, canvas.height);
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render the page into the <canvas> element.
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                await page.render(renderContext);
                console.log("Page rendered!");
            }
        } catch (error) {
            console.log(error);
        }
    })(pageNumber);
}, [pageNumber]); */


  return (
    <>
    <h1>View PDF</h1>
    <div id="pdf-container">
        <div
            id="pdf-bottom-control-container"
            className="pdf-control-container"
        >
        </div>
    </div>
    {numPages && <h3>{pageNumber} / {numPages}</h3>}
    {numPages && <button disabled={pageNumber===1} onClick={()=>setPageNumber((prev) => prev === 1 ? 1 : prev-1)}>Prev</button>}&nbsp;&nbsp;
    {numPages && <button disabled={pageNumber===numPages} onClick={()=>setPageNumber((prev) => prev === numPages ? numPages : prev+1)}>next</button>}
    <br />
    {loading && <h2>Loading ...</h2>}
    </>
  );
}

export default PdfViewer;