import { useCallback, useEffect, useState } from "react";
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { Form, Field } from "react-final-form";

const useStyles = makeStyles({
});

const ResetNewPassword = (props) => {
    const location = useLocation();
    console.log('location', location);
    console.log('location.pathname', location.pathname);
    const params_in_location_search = queryString.parse(location.search)
    console.log('params_in_location_search', params_in_location_search);
    console.log('token', params_in_location_search?.token);
    const formData = {};
    const classes = useStyles();

    const navigate = useNavigate();

    const styles = {};

    const [passwordResetToken, setPasswordResetToken] = useState(null);

    const setLocalStorage = useCallback(
        async (statusCode, values) => {
            if (statusCode === 200 && values.new_password) {
                const form_data = new FormData();
                form_data.set("username", "founderdesign@hotmail.com");
                form_data.set("password", values.new_password);

                const results = await axios.post(
                    "/api/v1/rest-auth/api-token-auth/",
                    form_data
                );
                console.log(results.data.token);

                localStorage.setItem("token", results.data.token);

                navigate("/localnews");
            }
        },
        [navigate]
    );

    useEffect(() => {
        (async () =>{
            const params_gotten_from_location_search = queryString.parse(location.search);
            const password_reset_token = params_gotten_from_location_search?.token
            try{
                const results = await axios.post(
                    "api/password_reset/validate_token/",
                    {
                        'token': password_reset_token,
                    },
                );
                console.log("results", results);
                if(results.status === 200){
                    setPasswordResetToken(password_reset_token)
                }else {
                    setPasswordResetToken('');
                }
            }catch (error){
                console.log("error", error);
                setPasswordResetToken('');
            }
        })();
        return ()=> setPasswordResetToken(null);
    }, [location]);

    const onSubmit = async (values) => {
        const results = await axios.post(
            "/api/password_reset/confirm/",
            {
                ...values,
                'token': params_in_location_search?.token,
            },
        );
        console.log("results", results);
        console.log("results?.data?.code", results?.data?.code);
        if(results?.status === 200) {
            // I may override ResetPasswordConfirmViewSet (Django) to get token direct instead of login again. - Hua
            navigate("/login");
        }
        
    };

    if(passwordResetToken === null){
        return (
            <h1>Reading data, please wait...</h1>
        );
    }

    if(passwordResetToken === ''){
        return (
            <h1>The token is invalid</h1>
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={classes.title}>
                React Final Form - Simple Example
            </div>
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    ...formData,
                }}
                validate={(values) => {
                    const errors = {};
                    if (!values.password) {
                        errors.password = "Required";
                    }
                    if (!values.confirm) {
                        errors.confirm = "Required";
                    } else if (values.confirm !== values.password) {
                        errors.confirm = "Must match";
                    }
                    return errors;
                }}
                render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} className={styles.form}>

                        <Field name="password">
                            {({ input, meta }) => (
                                <div>
                                    <label>New Password</label>
                                    <input
                                        {...input}
                                        type="password"
                                        placeholder="new Password"
                                    />
                                    {meta.error && meta.touched && (
                                        <span>{meta.error}</span>
                                    )}
                                </div>
                            )}
                        </Field>

                        <Field name="confirm">
                            {({ input, meta }) => (
                                <div>
                                    <label>Confirm</label>
                                    <input
                                        {...input}
                                        type="password"
                                        placeholder="confirm Password"
                                    />
                                    {meta.error && meta.touched && (
                                        <span>{meta.error}</span>
                                    )}
                                </div>
                            )}
                        </Field>

                        <div className={styles.buttons}>
                            <button
                                type="submit"
                                disabled={submitting || pristine}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={form.reset}
                                disabled={submitting || pristine}
                            >
                                Reset
                            </button>
                        </div>
                        <pre>{JSON.stringify(values, 0, 2)}</pre>
                        <pre>{JSON.stringify(formData, 0, 2)}</pre>
                    </form>
                )}
            />
        </div>
    );
};

export default ResetNewPassword;
