import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Form, Field } from "react-final-form";
import { authPutRequest } from "./utils/axiosRequest";

const useStyles = makeStyles({
    form: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
  });

const ChangePassword = (props) => {
    const formData = {};
    const classes = useStyles();

    const navigate = useNavigate();

    const styles ={}

    
    const onSubmit = async (values) => { 
        const results = await authPutRequest("/api/v1/users/changepassword/", values);
        console.log("results", results);
        console.log('token', results?.data?.refresh);

        localStorage.setItem('token', results.data.refresh);
        navigate('/localnews');
    };
  
    return (
      <div className={styles.wrapper}>
        <div className={classes.title}>React Final Form - Simple Example</div>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...formData,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.old_password) {
              errors.old_password = "Required";
            }
            if (!values.new_password) {
              errors.new_password = "Required";
            }
            if (!values.confirm) {
              errors.confirm = "Required";
            } else if (values.confirm !== values.new_password) {
              errors.confirm = "Must match";
            }
            return errors;
          }}
          render={({ handleSubmit, 
            form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field name="old_password">
                {({ input, meta }) => (
                <div>
                  <label>Old Password</label>
                  <input {...input} type="password" placeholder="Old Password" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>

                <Field name="new_password">
                {({ input, meta }) => (
                <div>
                  <label>New Password</label>
                  <input {...input} type="password" placeholder="new Password" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>

                <Field name="confirm">
                {({ input, meta }) => (
                <div>
                  <label>Confirm</label>
                  <input {...input} type="password" placeholder="confirm Password" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
                </Field>
              
              <div className={styles.buttons}>
                <button type="submit" disabled={submitting || pristine}>
                  Submit
                </button>
                <button
                  type="button"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  Reset
                </button>
              </div>
              <pre>{JSON.stringify(values, 0, 2)}</pre>
              <pre>{JSON.stringify(formData, 0, 2)}</pre>
            </form>
          )}
        />
      </div>
    );
};

export default ChangePassword;