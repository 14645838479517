import { useMemo } from 'react';
import {useRoutes} from 'react-router-dom';
import './App.css';
import Home from './Home';
import About from './About';
import LocalNews from './LocalNews';
import Login from './Login';
import LogoutToken from './LogoutToken';
import LogoutJWT from './LogoutJWT';
import AddLocalNews from './AddLocalNews';
import ChangePassword from './ChangePassword';
import ResetPasswordRequestConfirmed from './password/ResetPasswordRequestConfirmed';
import ResetPasswordRequest from './password/ResetPasswordRequest';
import ResetNewPassword from './password/ResetNewPassword';
import Registration from './password/Registration';
import PdfViewer from './pdf/PdfViewer';
import DownloadPDF from './pdf/DownloadPDF';
import PrintPDF from './pdf/PrintPDF';
import PrintCurrentPage from './pdf/PrintCurrentPage';
import UserProfile from './UserProfile';
import W404 from './W404';


function App() {
    const routeListData = useMemo(()=>([
        {path: "/", element: <Home />, title: "Home"}, //for useRoutes, we do not need 'title'
        {path: "/about", element: <About />, title: "About Us"}, //for useRoutes, we do not need 'title'
        {path: "/pdfviewer", element: <PdfViewer />, title: "Pdf Viewer"}, //for useRoutes, we do not need 'title'
        {path: "/downloadpdf", element: <DownloadPDF />, title: "Pdf download"}, //for useRoutes, we do not need 'title'
        {path: "/printpdf", element: <PrintPDF />, title: "print PDF"}, //for useRoutes, we do not need 'title'
        {path: "/printcurrentpage", element: <PrintCurrentPage />, title: "print current page"}, //for useRoutes, we do not need 'title'
        {path: "/localnews", element: <LocalNews />, title: "Local News"}, //for useRoutes, we do not need 'title'
        {path: "/login", element: <Login />, title: "Login"}, //for useRoutes, we do not need 'title'
        {path: "/logout_token", element: <LogoutToken />, title: "Logout Token"}, //for useRoutes, we do not need 'title'
        {path: "/logout_jwt", element: <LogoutJWT />, title: "Logout JWT"}, //for useRoutes, we do not need 'title'
        {path: "/addlocalnews", element: <AddLocalNews />, title: "Add Local News"}, //for useRoutes, we do not need 'title'
        {path: "/changepassword", element: <ChangePassword />, title: "Change Password"}, //for useRoutes, we do not need 'title'
        {path: "/resetpasswordrequestconfirmed", element: <ResetPasswordRequestConfirmed />, title: "Reset Password Request Confirmed"}, //for useRoutes, we do not need 'title'
        {path: "/resetpasswordrequest", element: <ResetPasswordRequest />, title: "Reset Password Request"}, //for useRoutes, we do not need 'title'
        {path: "/resetnewpassword", element: <ResetNewPassword />, title: "Reset new Password Request"}, //for useRoutes, we do not need 'title'
        {path: "/register", element: <Registration />, title: "Registration"}, //for useRoutes, we do not need 'title'
        {path: "/userprofile", element: <UserProfile />, title: "UserProfile"}, //for useRoutes, we do not need 'title'
        {path: "*", element: <W404 />}
    ]), []);

  return (
      <>
        <div className="App">
           <header>
             Hello all!
          </header>
          <div>
              {useRoutes(routeListData)}
          </div>
        </div>
     </>
  );
}

export default App;
