import { useNavigate, useLocation } from 'react-router-dom';
import {axiosInstance} from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    return async () => {
        console.log('call refresh token');
        try{
            const results = await axiosInstance.post("/api/v1/token/refresh/",
                { refresh: localStorage.getItem('token')} // Note: this is refresh token
            );
            setAuth(prev => {
                console.log(JSON.stringify(prev));
                console.log(results?.data?.access);
                return { ...prev, access: results?.data?.access }
            });
            return results?.data?.access;
        }catch (err){
            setAuth({});
            navigate('/login', { state: { from: location }, replace: true });
        }
            
    }
};

export default useRefreshToken;