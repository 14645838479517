import { useCallback, useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import { Form, Field } from "react-final-form";

const useStyles = makeStyles({
    form: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
  });

const AddLocalNews = () => {
    const formData = {};
    const classes = useStyles();

    const navigate = useNavigate();

    const styles ={}

    const config = {
        headers: { 
            "Authorization": `Token ${localStorage.getItem('token')}`
        },
        
    };
    const [localNews, setLocalNews] = useState(null);

    const attachmentsRef = useRef(null);

    useEffect(() => {
        const form_data = new FormData();
        form_data.set("title", "This is localnews title " + new Date().getTime());
        form_data.set("content", "This is the content of localnews");

        const config = {
            headers: { 
                "Content-Type": "multipart/form-data",
                "Authorization": `Token ${localStorage.getItem('token')}`
            },
            
        };

        (async () => {
            const results = await axios.post(
                "/api/v1/localnews/new",
                form_data,
                config);
            setLocalNews(results.data);
        })();
        return () => {
            setLocalNews(null);
        };
    }, []);

    const setLocalStorage = useCallback(
        async (statusCode, values) => {
            if(statusCode === 200 && values.new_password){
                const form_data = new FormData();
                form_data.set("username", "founderdesign@hotmail.com");
                form_data.set("password", values.new_password);

                const results = await axios.post("/api/v1/rest-auth/api-token-auth/", form_data);
                console.log(results.data.token);
    
                localStorage.setItem('token', results.data.token);
    
                navigate('/localnews');
            }
        }, [navigate]);
    
    const onSubmit = async (values) => { 
        const form_data = new FormData();
        form_data.set("title", values.title);
        form_data.set("content", values.content);

        console.log("values.files", values.files.files);

        for(let index=0; index<attachmentsRef.current.files.length; index++){
            let f= attachmentsRef.current.files[index];
            console.log(f)
            //form_data.append('file', f);
            form_data.append('files', f);
        }

        const results = await axios.post(
            "/api/v1/localnews/new",
            form_data,
            config);
        console.log("results", results);
        console.log("results?.data?.code", results?.data?.code);
        // await setLocalStorage(results?.data?.code, values);
    };
    
    return (
        <div className={styles.wrapper}>
          <div className={classes.title}>React Final Form - Add new Local News</div>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...formData,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.title) {
                errors.title = "Required";
              }
              if (!values.content) {
                errors.content = "Required";
              }
              if (!values.files) {
                errors.files = "Required";
              }
              return errors;
            }}
            render={({ handleSubmit, 
              form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                  <Field name="title">
                  {({ input, meta }) => (
                  <div>
                    <label>Title</label>
                    <input {...input} type="text" placeholder="Title" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
                  </Field>
  
                  <Field name="content">
                  {({ input, meta }) => (
                  <div>
                    <label>Content</label>
                    <input {...input} type="text" placeholder="Content" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
                  </Field>
  
                  <Field name="files">
                  {({ input, meta }) => (
                  <div>
                    <label>Upload Files</label>
                    <input {...input} type="file" multiple placeholder="upload files" ref={attachmentsRef} />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
                  </Field>
                
                <div className={styles.buttons}>
                  <button type="submit" disabled={submitting || pristine}>
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                  >
                    Reset
                  </button>
                </div>
                <pre>{JSON.stringify(values, 0, 2)}</pre>
                <pre>{JSON.stringify(formData, 0, 2)}</pre>
              </form>
            )}
          />
        </div>
      );
  };

export default AddLocalNews;
