import { useState, useEffect } from "react";
import axios from "axios";
import printJS from "print-js";

const PrintCurrentPage = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    //https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
    const printData = () => {
        
        return new Promise((resolve, reject) => {
            const array = [1, 2];
            printJS({
                printable: 'print-area',
                type: "html",
                showModal: true,
                documentTitle: "we add document title here",
                // onPrintDialogClose: () => { console.log("close"); setButtonDisabled(false); resolve(array)}, //this does not work on Firefox
                onError: (err) => {
                    console.log("error");
                    reject(err);
                },
                onLoadingStart: () => {
                    console.log("loading...");
                    resolve(array);
                },
            });
        });
    };

    const clickToPrint = async () => {
        setButtonDisabled(true);
        
        try {
            const result = await printData();
            console.log("result", result);
        } catch (error) {
            console.log("error in catch block", error);
        } finally {
            console.log("This is in finally block");
            setButtonDisabled(false);
        }
    };

    return (
        <>
        <div id="print-area">
            <h1>sth is header</h1>
            <p>lsfjlsfjslfjslfjsldjf</p>
            <p>abc abc abc abc</p>
        </div>
            <button onClick={clickToPrint} disabled={isButtonDisabled}>
                {isButtonDisabled ? "Wait..." : "Click to print"}
            </button>
        </>
    );
};

export default PrintCurrentPage;
