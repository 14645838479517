import { axiosInstancePrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {

        const requestIntercept = axiosInstancePrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.access}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosInstancePrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                console.log('error?.response?.status', error?.response?.status);
                console.log('prevRequest?.sent', prevRequest?.sent);
                if ((error?.response?.status === 401 && error?.response?.data?.code ==='token_not_valid') && !prevRequest?.sent) {
                    console.log("have to refresh token");
                    console.log('error?.response', error?.response);
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosInstancePrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstancePrivate.interceptors.request.eject(requestIntercept);
            axiosInstancePrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    // return axiosInstancePrivate; //This is the old version
    return (request) =>{
        try{
            return axiosInstancePrivate(request);
        }catch (err) {
            console.error(err);
        }
    }
}

export default useAxiosPrivate;