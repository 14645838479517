import React, { Suspense }  from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import reportWebVitals from './reportWebVitals';

axios.defaults.baseURL = 'https://linglingli.ca';

ReactDOM.render(
  <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
          <AuthProvider>
            <Router>
                <App />
            </Router>
          </AuthProvider>
      </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
